<template>
    <div
        v-infinite-scroll="onLoadMore"
        class="grid grid-flow-row auto-rows-max grid-cols-1 md:grid-cols-3 gap-12 xl:gap-[min(8rem,10vw)]"
        infinite-scroll-disabled="disableLazyLoad"
        infinite-scroll-distance="10"
        infinite-scroll-immediate-check="false"
    >
        <a
            v-for="project in entries" :key="project.id"
            class="project project--alternate group"
            :href="project.url"
            data-aos="fade-up"
            data-aos-offset="200"
        >
            <image-asset
                :asset="getFeaturedImage(project)"
                img-class="block sm:absolute sm:top-0 sm:left-0 w-full sm:h-full sm:object-cover sm:object-center bg-gray-100"
            />

            <div class="to-sm:mt-2 sm:absolute sm:flex sm:inset-0 sm:items-center sm:opacity-0 sm:text-white sm:z-1 transition-opacity ease-in-out duration-500 group-hover:opacity-100">
                <div class="w-full text-center sm:p-8">
                    <p class="h5 font-light">{{ project.title }}</p>

                    <div class="to-sm:hidden absolute bottom-12 left-0 w-full translate-transform ease-in-out duration-500 translate translate-y-6 group-hover:translate-y-0">
                        <disclosure class="block w-4 h-4 mt-4 mx-auto disclosure" />
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
    import ImageAsset from './ImageAsset.vue';
    import Disclosure from '../../img/icons/icon-arrow.svg';
    import infiniteScroll from 'vue-infinite-scroll';
    import { GET_PROJECTS_QUERY } from '../graphql/queries/projects';

    export default {
        directives: {
            infiniteScroll,
        },

        components: {
            ImageAsset,
            Disclosure,
        },

        data() {
            return {
                entries: [],
                page: 1,
                entriesPerPage: 12,
                disableLazyLoad: true,
            };
        },

        apollo: {
            entries: {
                query: GET_PROJECTS_QUERY,
                variables() {
                    return {
                        limit: this.entriesPerPage,
                        offset: 0,
                    };
                },
                update(data) {
                    this.disableLazyLoad = data.entries.length < this.entriesPerPage;
                    return data.entries;
                },
            },
        },

        methods: {
            getFeaturedImage(project) {
                const { projectThumbnail: [image] } = project;

                if (!image) {
                    return project.projectFeaturedImage[0];
                }

                return image;
            },
            onLoadMore() {
                this.page++;

                const offset = this.page * this.entriesPerPage;

                this.$apollo.queries.entries.fetchMore({
                    variables: {
                        limit: this.entriesPerPage,
                        offset,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const entries = [...previousResult.entries, ...fetchMoreResult.entries];

                        this.$nextTick(() => {
                            this.$set(this, 'disableLazyLoad', fetchMoreResult.entries.length < this.entriesPerPage);
                        });

                        return {
                            entries,
                        };
                    },
                });
            },
        },
    };
</script>

<style scoped>
    :deep(.disclosure) path {
        fill: #fff;
    }
</style>
