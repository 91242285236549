<template>
    <header>
        <slot :is-open="isOpen" :is-shown="isShown" :on-toggle="onToggle" :on-after-enter="onAfterEnter" />
    </header>
</template>

<script>
    export default {
        data() {
            return {
                isOpen: false,
                isShown: false,
            };
        },

        methods: {
            onToggle() {
                this.isOpen = !this.isOpen;
                if (!this.isOpen) {
                    this.isShown = false;
                }
            },
            onAfterEnter() {
                this.isShown = true;
            },
        },
    };
</script>
