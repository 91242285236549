<template>
    <picture v-if="firstAsset">
        <source
            v-for="source in sources"
            :key="source.key"
            :type="source.type"
            :media="source.media"
            :sizes="source.sizes"
            :srcset="source.srcset"
            :data-srcset="source['data-srcset']"
        />

        <img
            class="lazyload"
            :class="classes"
            :src="placeholder"
            :data-src="firstAsset.src"
            :data-srcset="firstAsset.srcset"
            :style="focalPoint"
            :alt="alt"
        />
    </picture>
</template>

<script>
    import isPlainObject from 'lodash/isPlainObject';
    import { svgPlaceholder } from '../utils/svg';

    export default {
        name: 'ImageAsset',

        props: {
            asset: {
                type: [Array, Object, String, null],
                default: null,
            },
            cover: {
                type: Boolean,
                default: false,
            },
            contain: {
                type: Boolean,
                default: false,
            },
            align: {
                type: String,
                default: 'center',
                validator: (value) => {
                    return ['center', 'top', 'bottom'].includes(value);
                },
            },
            alt: {
                type: String,
                default(props) {
                    if (props.asset === null) {
                        return '';
                    }

                    if (isPlainObject(props.asset)) {
                        return props.asset.title;
                    }

                    if (props.asset.length > 0) {
                        return props.asset[0].title;
                    }
                },
            },
            imgClass: {
                type: [Array, Object, String],
                default: '',
            },
        },

        computed: {
            firstAsset() {
                if (this.asset !== null) {
                    if (isPlainObject(this.asset)) {
                        return this.asset;
                    }

                    if (this.asset.length > 0) {
                        return this.asset[0];
                    }
                }

                return null;
            },

            sources() {
                return [
                    ...this.getSrcsets(true),
                    ...this.getSrcsets(),
                ];
            },

            placeholder() {
                return svgPlaceholder(this.firstAsset.width, this.firstAsset.height);
            },

            focalPoint() {
                if (!this.firstAsset.hasFocalPoint) {
                    return null;
                }

                const [x, y] = this.firstAsset.focalPoint;

                return {
                    'object-position': `${x * 100}% ${y * 100}%`,
                };
            },

            classes() {
                return [{
                    'absolute top-0 left-0 w-full h-full object-cover': this.cover,
                    'absolute top-0 left-0 w-full h-full object-contain': this.contain,
                    'object-center': this.align === 'center',
                    'object-top': this.align === 'top',
                    'object-bottom': this.align === 'bottom',
                }, this.imgClass];
            },
        },

        methods: {
            getSrcsets(webp = false) {
                const screens = [
                    'xl',
                    'lg',
                    'md',
                    'sm',
                ];

                const sources = [];

                for (const screen of screens) {
                    const srcset = this.getSrcset(`${webp ? 'webp_' : ''}${screen}_srcset`, screen, webp);
                    if (srcset) {
                        sources.push(srcset);
                    }
                }

                const srcset = this.getSrcset(`${webp ? 'webp_' : ''}srcset`, null, webp);
                if (srcset) {
                    sources.push(srcset);
                }

                return sources;
            },

            getSrcset(key, screen, webp) {
                const screens = {
                    xs: 520, sm: 640, md: 768, lg: 1024, xl: 1280, '2xl': 1536,
                };

                const srcset = this.firstAsset[key];

                if (typeof srcset !== 'undefined') {
                    const matches = /_(?<width>\d+)x(?<height>\d+)_.*?(?<w>\d+w)/.exec(srcset);
                    const placeholder = matches ? `${svgPlaceholder(
                        matches.groups.width,
                        matches.groups.height,
                    )} ${matches.groups.w}` : null;

                    const params = {
                        key,
                        sizes: 'auto',
                        type: webp ? 'image/webp' : this.firstAsset.mimeType,
                        srcset: placeholder,
                        'data-srcset': srcset,
                    };

                    if (screen) {
                        params.media = `(min-width: ${screens[screen]})`;
                    }

                    return params;
                }

                return null;
            },
        },
    };
</script>

<style lang="scss" scoped>
    :deep(iframe) {
        @apply block w-full h-full;
    }
</style>
