<template>
    <section>
        <slot :show-video="showVideo" :on-show-video="onShowVideo" />
    </section>
</template>

<script>
    export default {
        data() {
            return {
                showVideo: false,
            };
        },

        methods: {
            onShowVideo() {
                this.showVideo = true;
            },
        },
    };
</script>
