import { gql } from 'graphql-tag';

export const GET_PROJECTS_QUERY = gql`
    query($limit: Int, $offset: Int) {
        entries(section: "projects", limit: $limit, offset: $offset, orderBy: "postDate desc") {
            id
            url
            title

            ... on projects_default_Entry {
                projectFeaturedImage {
                    id
                    title
                    width
                    height
                    mimeType
                    focalPoint
                    hasFocalPoint
                    url @transform(width: 360, height: 200, immediately: false)
                    srcset: url @srcset(ratio: 0.5625, immediately: false)
                    webp_srcset: url @srcset(ratio: 0.5625, format: "webp", immediately: false)
                }
                
                projectThumbnail {
                    id
                    title
                    width
                    height
                    mimeType
                    focalPoint
                    hasFocalPoint
                    url @transform(width: 360, height: 200, immediately: false)
                    srcset: url @srcset(ratio: 0.5625, immediately: false)
                    webp_srcset: url @srcset(ratio: 0.5625, format: "webp", immediately: false)
                }
            }
        }
    }
`;
