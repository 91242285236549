// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';
import 'aos/dist/aos.css';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// An excellent, responsive and lightweight lightbox
// import 'spotlight.js';


// ================================================
// TUI Components
// ================================================

import MainMenu from './components/MainMenu.vue';
import Projects from './components/Projects.vue';
import Project from './components/Project.vue';
import Rot13Email from './components/Rot13Email.vue';
import Accordion from './components/Accordion.vue';
import Lightbox from './components/Lightbox.vue';
import CollapseTransition from './components/CollapseTransition.vue';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
    Autoplay,
    EffectCreative,
    Lazy,
    Navigation,
    Pagination,
    Parallax,
} from 'swiper';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

import AOS from 'aos';

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: '/api',
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

const app = createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        Swiper,
        SwiperSlide,
        Projects,
        Project,
        MainMenu,
        Rot13Email,
        Accordion,
        Lightbox,
        CollapseTransition,
    },

    data() {
        return {
            loaded: false,
            swiperModules: [Autoplay, EffectCreative, Lazy, Navigation, Pagination, Parallax],
        };
    },

    mounted() {
        AOS.init({
            duration: 1000,
            startEvent: 'load',
        });

        setTimeout(() => {
            this.loaded = true;
        }, 500);
    },
});

app.use(apolloProvider);

app.mount('#app');
